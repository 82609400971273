import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import image_security from '../../images/security-badge.png'
import { BP_SMALL, BP_MEDIUM } from '../../styles/theme'

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  text-align: justify;
  align-items: center;
  padding: 1rem 2rem;
  margin: 4rem 0;
  color: ${props => props.theme.textDark};
  @media only screen and (max-width: ${BP_MEDIUM}) {
    flex-direction: column;
    padding: 0;
  }
`
const CardContent = styled.div`
  padding: 1rem 2rem;
  max-width: 600px;
  border-radius: 10px;
  background-color: white;
  text-align: justify;
  @media only screen and (max-width: ${BP_SMALL}) {
    margin: 1rem 0;
  }
`

const TitleSection = styled.h1`
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.primary};
  text-transform: uppercase;
  font-size: 30px;
  ${props =>
    props.black &&
    css`
      color: ${props => props.theme.backgroundFooter};
    `};
`

const ItemContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: white;
  margin: 1rem 3rem;
  padding-top: 8px;
  padding-bottom: 40px;
  width: 15%;
  &:hover {
    color: ${props => props.theme.primaryLight};
  }
  @media only screen and (max-width: ${BP_SMALL}) {
    width: 90%;
  }
`
const TitleCard = styled.h2`
  text-align: ${props => props.textAlign};
  color: ${props => props.theme.primary};
`

const ImageStyled = styled(Img)`
  margin: 0 2rem;
  box-shadow: var(--shadow-dark);
  /* height: 250px; */
  border-radius: 20px;
  @media only screen and (max-width: ${BP_SMALL}) {
    height: 200px;
  }
`
const ResponsiveImage = styled.img`
  height: 60px;
  @media only screen and (max-width: ${BP_MEDIUM}) {
    height: 50px;
  }
`

const FillingBackground = styled.div`
	width: 100%;
	height: 50%;
	position: absolute;
	z-index: -1;
	bottom: 0;
	${props =>
    props.top &&
    css`
      top: 0;
      left: 0;
    `} background-color: ${props => props.theme.primary};
`
const GradientContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 160px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 65%,
      white 85%
    ),
    linear-gradient(-90deg, rgba(255, 255, 255, 0) 65%, white 85%);
`
const ImageSecurityBadge = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  background-image: url(${image_security});
  background-size: 60px;
  background-repeat: no-repeat;
  background-position: center;

  &::after {
    content: '0';
    font-size: 3rem;
    font-weight: bold;
    color: ${props => props.theme.green};
  }
`
const AdjustTwoColumnContent = styled.div`
  margin: 0 3rem;
  position: relative;
  @media only screen and (max-width: ${BP_SMALL}) {
    margin: 0;
  }
`

const TwoColumns = styled.div`
  width: 50%;
  margin: 0 1rem;
  text-align: justify;
  position: relative;
  @media only screen and (max-width: ${BP_SMALL}) {
    width: 100%;
  }
`

const LimitTwoColumns = styled(TwoColumns)`
  max-width: 500px;
`
const TwoColumnsContent = styled.div`
  position: absolute;
  z-index: 10;
  color: white;
  width: 100%;
  padding: 1rem;
  max-width: 750px;
`
export {
  SectionContainer,
  CardContent,
  ItemContainer,
  TitleSection,
  TitleCard,
  ImageStyled,
  AdjustTwoColumnContent,
  FillingBackground,
  ImageSecurityBadge,
  GradientContainer,
  ResponsiveImage,
  TwoColumns,
  TwoColumnsContent,
  LimitTwoColumns,
}
