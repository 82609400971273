import styled, { css } from 'styled-components'
import { Row } from 'react-styled-flexboxgrid'
import section2_background from '../../images/section2_background.png'
import section3_background from '../../images/section3_background.png'

import { BP_SMALL } from '../../styles/theme'

const SectionRowStyled = styled(Row)`
  overflow: hidden;
  position: relative;
  /* Modificar min-height para sectiosn con un tamaño especifico */

  padding: 2rem 0;
  ${props =>
    props.height &&
    css`
      min-height: ${props.height};
    `};

  ${props => {
    let backgroundImage = props.backgroundImage || ''
    switch (backgroundImage) {
      case 'section2':
        return css`
          background-image: url(${section2_background});
          background-size: 100% 1100px;
          background-position: 1rem -4rem;
          background-repeat: no-repeat;
        `
      case 'section3':
        return css`
          background-image: url(${section3_background});
          background-size: 100% calc(100% + 10px);
          background-repeat: no-repeat;
          /* Modificar para responsividad */
          background-position: 1rem 0;
          @media only screen and (max-width: ${BP_SMALL}) {
            background: none;
          }
        `
      default:
        return css`
          background-image: url(${backgroundImage});
          background-size: cover;
        `
    }
  }};
`

export { SectionRowStyled }
