import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/Layout'
import Section from '../components/common/Section'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import {
  SectionContainer,
  ImageStyled,
  TitleCard,
} from '../components/common/Index.styled'

import mision_vision_background from '../images/mision_vision_background.jpeg'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

const Mision = props => {
  const images = _.get(props, 'data.allFile.edges')
  return (
    <Layout
      title="Misión - Visión"
      description="Somos una firma con más de 26 años de experiencia especializada en brindar soluciones contables, fiscales, legales y administrativas"
      {...props}
    >
      <SectionHeaderImage image={mision_vision_background} position="0 70%" />
      <Section>
        <SectionContainer>
          <div style={{ maxWidth: '600px' }}>
            <TitleCard textAlign="left">
              <FormattedMessage id="aboutUs.mission.title" />
            </TitleCard>
            <p>
              <FormattedHTMLMessage id="aboutUs.mission.p1" />
            </p>
          </div>
          <ImageStyled
            fixed={_.get(
              images.find(({ node }) => node.name === 'mision'),
              'node.childImageSharp.fixed'
            )}
          />
        </SectionContainer>

        <SectionContainer>
          <ImageStyled
            fixed={_.get(
              images.find(({ node }) => node.name === 'vision'),
              'node.childImageSharp.fixed'
            )}
          />
          <div style={{ maxWidth: '600px' }}>
            <TitleCard textAlign="left">
              <FormattedMessage id="aboutUs.view.title" />
            </TitleCard>
            <p>
              <FormattedHTMLMessage id="aboutUs.view.p1" />
            </p>
          </div>
        </SectionContainer>

        <SectionContainer>
          <div style={{ maxWidth: '600px' }}>
            <TitleCard textAlign="left">
              <FormattedMessage id="aboutUs.culture_values.title" />
            </TitleCard>
            <p>
              <FormattedHTMLMessage id="aboutUs.culture_values.p1" />
            </p>
            <FooterTitle textAlign="center">
              <FormattedMessage id="aboutUs.culture_values.fotter" />
            </FooterTitle>
          </div>
          <ImageStyled
            fixed={_.get(
              images.find(({ node }) => node.name === 'culture_values'),
              'node.childImageSharp.fixed'
            )}
          />
        </SectionContainer>
      </Section>
    </Layout>
  )
}

const FooterTitle = styled(TitleCard)`
  font-size: 14px;
  margin-left: auto;
  max-width: 300px;
`
export const pageQuery = graphql`
  query MisionQuery {
    allFile(
      filter: {
        relativePath: { in: ["mision.jpg", "vision.png", "culture_values.png"] }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fixed(height: 220) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

export default Mision
