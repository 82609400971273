import React from 'react'
import { ContainerImage, Image, Content } from './SectionHeaderImage.styled'

const SectionHeaderImage = ({ image, position, children, large = false }) => (
  <ContainerImage large={large}>
    <Image src={image} alt="Background" position={position} large={large} />
    <Content>{children}</Content>
  </ContainerImage>
)

export default SectionHeaderImage
