import styled, { css } from 'styled-components'
import hex2rgba from '../../utils/hex2rgba'

const ContainerImage = styled.div`
  width: 100%;
  height: 240px;
  position: relative;
  ${props =>
    props.large &&
    css`
      height: 440px;
    `}
  &::before {
    content: '';
    position: absolute;
    top: 0;
    z-index: 5;
    width: 100%;
    height: 240px;
    background-color: ${props => hex2rgba(props.theme.primary, 0.6)};

    ${props =>
      props.large &&
      css`
        height: 440px;
      `}
  }
`
const Image = styled.img`
  object-fit: cover;
  height: 240px;
  width: 100%;
  object-position: ${props => props.position};

  ${props =>
    props.large &&
    css`
      height: 440px;
    `}
`
const Content = styled.div`
  position: absolute;
  padding: 0 1rem;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export { ContainerImage, Image, Content }
