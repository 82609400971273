import React from 'react'
import { Col } from 'react-styled-flexboxgrid'
import { SectionRowStyled } from './Section.styled'

const Section = ({ children, id, ...other }) => (
  <section id={id}>
    <SectionRowStyled center="xs" first="xs" {...other}>
      <Col xs={11} lg={9}>
        {children}
      </Col>
    </SectionRowStyled>
  </section>
)

export default Section
